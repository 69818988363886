const script = document.querySelector('#js-partners-banner-script');
const params = script.dataset;

// css
var style = document.createElement('link');
style.rel = "stylesheet";
style.type = "text/css";
style.href = "https://www.lonsdale.fr/wp-content/themes/lonsdale-odyssee/assets/css/partners_banner.css";
document.head.appendChild(style);

// load banner
const formData = new FormData();
formData.append('action', 'partners_banner');

if (params.lang) formData.append('lang', params.lang);

// load el
const xhr = new XMLHttpRequest();
xhr.open('POST', 'https://www.lonsdale.fr/wp-admin/admin-ajax.php', true);
xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 0)) {
        const response = JSON.parse(xhr.response);
        const el = document.createElement('div');
        el.id = "partners-banner";
        el.innerHTML = response.html;

        if (params.backgroundColor) el.style.setProperty('--background-color', params.backgroundColor);
        if (params.borderColor) el.style.setProperty('--border-color', params.borderColor);
        if (params.highlightColor) el.style.setProperty('--highlight-color', params.highlightColor);
        if (params.color) el.style.setProperty('--color', params.color);
        if (params.backgroundColorActive) el.style.setProperty('--background-color-active', params.backgroundColorActive);
        if (params.borderColorActive) el.style.setProperty('--border-color-active', params.borderColorActive);
        if (params.colorActive) el.style.setProperty('--color-active', params.colorActive);

        const btn = el.querySelector(".js-partners-banner-btn");
        const expand = el.querySelector('.js-partners-banner-expand');

        var open = false;
        btn.addEventListener('click', () => {
            open = !open;

            if (params.onOpen !== undefined) {
                expand.addEventListener('transitionend', () => {
                    if (!open) return;
                    window[params.onOpen].call();
                }, { once: true })
            }

            if (params.onClose !== undefined) {
                expand.addEventListener('transitionend', () => {
                    if (open) return;
                    window[params.onClose].call();
                }, { once: true })
            }

            el.classList.toggle('active');
        })

        script.after(el);

        if (params.onLoad !== undefined) {
            window[params.onLoad].call();
        }
    }
};

xhr.send(formData);